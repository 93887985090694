import React from 'react'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import NotFoundIcon from '../assets/svg/notFound.inline.svg'

const NotFoundPage = data => {
    return (
        <Layout>
            <Seo title='404: Not Found' meta={{ metaDesc: 'Page not found' }} />
            <div className='global-container' style={{ textAlign: 'center' }}>
                <h1>Страница не найдена</h1>
                <p>Вы только что попали на несуществующий урл... очень жаль.</p>
                <div style={{ transform: `translateY(15%)` }}>
                    <NotFoundIcon />
                </div>
            </div>
        </Layout>
    )
}

export default NotFoundPage
